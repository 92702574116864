import React, { useState, useMemo, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import logo from './VoteVibeLogoTransparent.png';
import { blue, red } from '@mui/material/colors';
import * as VoteVibeApi from '../../api/votevibeApi';

const sentimentAll = 1000;
const sentiments = {0: "Anger", 1: "Anticipation", 2: "Disgust", 3: "Fear", 4: "Joy", 5: "Love", 6: "Optimism", 
                7: "Pessimism", 8: "Sadness", 9: "Surprise", 10: "Trust"}
const sentimentColors = [
  '#8b0028',
  '#7a2aff',
  '#ffaa23',
  '#98ff98',
  '#8b0028',
  '#7a2aff',
  '#ffaa23',
  '#98ff98',
  '#8b0028',
  '#7a2aff',
  '#ffaa23',
];
const dummyData = [{date: 0, sentiments: [] }]

// Create a theme with a light blue background
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: blue,
    secondary: red,
    background: {
      default: '#141c1e',
    }
  },
});

function App() {
  const [selectedCandidate, setSelectedCandidate] = useState('Trump');
  const [selectedSentiment, setSelectedSentiment] = useState(1000);
  const [trumpData, setTrumpData] = useState<VoteVibeApi.DailySentiment[]>(dummyData);
  const [harrisData, setHarrisData] = useState<VoteVibeApi.DailySentiment[]>(dummyData);

  const data = selectedCandidate === 'Trump' ? trumpData : harrisData;

  const filteredData = useMemo(() => {
    if (selectedSentiment === sentimentAll) {
      return data;
    }
    return data.map(item => ({
      date: item.date,
      sentiments: [ item.sentiments?.find(s => s.key === selectedSentiment) ]
    }));
  }, [data, selectedSentiment]);

  useEffect(() => {
    var SentimentsApi = new VoteVibeApi.Api({baseUrl: 'https://api.unixile.de'});
    var request = SentimentsApi.sentiments.listSentimentBuckets().then((result) => {
      console.log(result);
      setTrumpData(result.data.find(c => c.trump_harris === 0)?.daily_sentiments ?? dummyData)
      setHarrisData(result.data.find(c => c.trump_harris === 1)?.daily_sentiments ?? dummyData)
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth='lg'>
        <Box position='absolute' left='0px' top='0'>
          <Typography fontSize={300} fontWeight={600} lineHeight={1.2} color='black' sx={{ opacity: 0.4, userSelect:'none', transform:'translate(-100%) rotate(-90deg)',  transformOrigin: 'top right'}}>
            VOTE
          </Typography>
        </Box>
        <Box position='absolute' right='0px' top='0'>
          <Typography fontSize={300} fontWeight={600} letterSpacing={35} lineHeight={1.2} color='black' sx={{ opacity: 0.4, userSelect:'none', transform:'translate(100%) rotate(90deg)',  transformOrigin: 'top left'}}>
            VIBE
          </Typography>
        </Box>
        <Box sx={{ mt:1, backgroundColor:'#0000000' }}>
          <Card elevation={0} sx={{ backgroundColor:'transparent', boxShadow: 'none', position:'relative' }}>
            <CardContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mb: 2 }}>
                <ButtonGroup variant="outlined" size='large' aria-label="candidate selection">
                  <Button sx={{ borderWidth: '4px', opacity: selectedCandidate === 'Trump' ? '1' : '0.25', fontSize:20, fontWeight:600}}
                    onClick={() => setSelectedCandidate('Trump')}
                    color={selectedCandidate === 'Trump' ? 'secondary' : 'inherit'}
                  >
                    Trump
                  </Button>
                  <Button sx={{ borderWidth: '4px', opacity: selectedCandidate === 'Harris' ? '1' : '0.25', fontSize:20, fontWeight:600}}
                    onClick={() => setSelectedCandidate('Harris')}
                    color={selectedCandidate === 'Harris' ? 'primary' : 'inherit'}
                  >
                    Harris
                  </Button>
                </ButtonGroup>
              </Box>
              <ResponsiveContainer width="100%" height={700}>
                <LineChart data={filteredData} style={{ zIndex: 1}}>
                  <XAxis dataKey={data => (new Date(data['date'] * 1000)).toDateString() } stroke='#ffffff' strokeWidth={1}/>
                  {/*<YAxis stroke='#ffffff'/>*/}
                  <Tooltip />
                  <Legend verticalAlign='top'/>
                  {Object.entries(sentiments).map(([idx, sentiment]) => (
                    <Line
                      key={sentiment}
                      type="monotone"
                      name={sentiment}
                      dataKey={data => {
                        var sentimentData = data.sentiments.find((s: VoteVibeApi.Sentiment) => s ? s['key'] == +idx : false);
                        return sentimentData ? sentimentData['count'] : 0;
                      }}
                      stroke={sentimentColors[+idx]}
                      strokeWidth={3}
                      activeDot={{ r: 4 }}
                      dot={false}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
              
              <Box marginTop='40px' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mb: 2 }}>
                <ButtonGroup variant="contained" aria-label="sentiment filter">
                  <Button
                    onClick={() => setSelectedSentiment(sentimentAll)}
                    color={selectedSentiment === sentimentAll ? 'primary' : 'inherit'}
                  >
                    All
                  </Button>
                  {Object.entries(sentiments).map(([idx, sentiment]) => (
                    
                    <Button
                      key={+idx}
                      onClick={() => setSelectedSentiment(+idx)}
                      color={selectedSentiment === +idx ? 'primary' : 'inherit'}
                    >
                      {sentiment}
                    </Button>
                  ))}
                </ButtonGroup>
              </Box>
              
              {/*<Box position='absolute' bottom='7%' right='20px'>
                <Typography variant="h1" gutterBottom align="center" fontWeight={600} color='#0a0e0f'>
                    Vote Vibe
                </Typography>
                  </Box>*/}
            </CardContent>
          </Card>
          {/*<img src={logo} width={300}/>*/}
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;

